import headerImage from '../../assets/images/blog/2015-08-25.jpg';

const Blog20150825 = {
  id: 20150825,
  title: "Spotlight of the Week: Advisory Board Member Shannon O'Malley",
  type: 'interview',
  publishDate: '2015-08-25',
  headerImage,
  summary:
    "Meet Shannon O'Malley, the brilliant inspiration for our spotlight interviews as well as a dedicated member serving on our advisory board! Read on to get a peek into her world.",
  body: [
    {
      type: 'lead',
      text:
        "Meet Shannon O'Malley, the brilliant inspiration for our spotlight interviews as well as a dedicated member serving on our advisory board!"
    },
    {
      type: 'paragraph',
      text:
        "I came to Portland in the fall of 2012 to pursue a job with the Nike Foundation after finishing up work with a nonprofit in Thailand. After settling a bit here, I really wanted to get more involved and had started coaching high school lacrosse. It was there that I connected with Alisha and couldn't help but be inspired by her vision for Kids First Project."
    },
    {
      type: 'paragraph',
      text: 'The mantra I live by is, "Work hard and be nice to people."'
    },
    {
      type: 'strong',
      text: 'What does Kids First Project mean to you?'
    },
    {
      type: 'paragraph',
      text:
        "I have seen the power of education and empowerment of youth first hand in various developing countries that I worked in, and I'm excited to finally be in a place where I can be investing in my local community. I think Kids First Project is so key in helping to break the cycle of poverty and make lasting change."
    },
    {
      type: 'strong',
      text: 'What is your spirit animal?'
    },
    {
      type: 'paragraph',
      text:
        "My spirit animal is a Unicorn - A friend chose my spirit animal for me years ago and I've been channeling my inner unicorn ever since. Side note: I also didn't know unicorns weren't real until pretty late in life..."
    },
    {
      type: 'strong',
      text: 'What is something most people do not know about you?'
    },
    {
      type: 'paragraph',
      text: "Most people don't know that I'm left handed."
    },
    {
      type: 'strong',
      text: 'Describe the color yellow to somebody who is blind.'
    },
    {
      type: 'paragraph',
      text: 'It is the warm, happy, gentle hum in your soul.'
    },
    {
      type: 'strong',
      text: 'What is the weirdest food combo you have ever eaten?'
    },
    {
      type: 'paragraph',
      text: 'Raw meat with sticky rice'
    },
    {
      type: 'strong',
      text:
        'If somebody wrote a biography about you, what do you think the title should be?'
    },
    {
      type: 'paragraph',
      text:
        'I think they already did, "The Very Hungry Caterpillar." ;) (This is a hard one!!) Maybe "A Girl and her Sneakers?"'
    },
    {
      type: 'strong',
      text: 'What is your intent?'
    },
    {
      type: 'paragraph',
      text: 'Strength'
    }
  ]
};

export default Blog20150825;

import React from 'react';

const PhsyicalActivity = () => {
  return (
    <React.Fragment>
      <h3>Physical Activity</h3>
      <p>
        With classic games like Duck, Duck, Goose and the parachute game, we
        teach children teamwork and communication while also promoting an active
        lifestyle through our physical activity programs. We will also be
        bringing in sport specific activities to help children become involved
        in team sports as well as different forms of exercise, such as yoga.
      </p>
    </React.Fragment>
  );
};

export default PhsyicalActivity;

const Blog20160920 = {
  id: 20160920,
  title: 'How We Should Really Bridge the Inequality Gap',
  type: 'text',
  publishDate: '2016-09-20',
  summary:
    'Jay Z can afford to drop fifty five million dollars on a racehorse without batting an eye, and as a member of a capitalist society, he has every right to do so. Yet, every time I see the sharp juxtaposition of celebrities and millionaires against the ever growing homeless population in Portland, Oregon, I am struck by the feeling that something is fundamentally wrong. These examples of the richest of the rich being able to afford everything their heart desires while the majority of Americans live from paycheck to paycheck exemplifies the wealth inequality which plagues our world and renders upward mobility impossible.',
  body: [
    {
      type: 'paragraph',
      text:
        'Jay Z can afford to drop fifty five million dollars on a racehorse without batting an eye, and as a member of a capitalist society, he has every right to do so. Yet, every time I see the sharp juxtaposition of celebrities and millionaires against the ever growing homeless population in Portland, Oregon, I am struck by the feeling that something is fundamentally wrong. These examples of the richest of the rich being able to afford everything their heart desires while the majority of Americans live from paycheck to paycheck exemplifies the wealth inequality which plagues our world and renders upward mobility impossible.'
    },
    {
      type: 'paragraph',
      text:
        'This societal issue is clearly present across the globe, but because I am witness to the staggering wealth inequality in the United States, I feel best equipped to address the issue and its importance with regards to the United States. I have often heard wealth redistribution and steeper taxes on the rich proposed as potential solutions to the problem. Absent a plan for strategic investment of these dollars, I see no solution to wealth inequality. Investment in educational equality, however, will address the cycle of poverty. I work on the administrative team of a youth volunteer organization called Kids First Project whose mission is to help end the cycle of poverty by providing opportunities to allow homeless youth to reach their full potential. In working with youth in shelter, I have noticed that their socioeconomic status severely limits their educational opportunities. Beginning before kindergarten, the educational system is stacked against youth in poverty. They attend poorly performing schools with low graduation rates and high dropout rates. They don’t have the same resources and support in elementary, middle, or high school. Most of the youth I have talked with look to obtain a high school GED before continuing straight into the workforce. Without a complete education or college degree, getting a secure, higher paying job is challenging, making the cycle of poverty inescapable and widening the gap between the rich and the poor.'
    },
    {
      type: 'paragraph',
      text:
        'Every person deserves the opportunity to succeed, regardless of socioeconomic status. When the system is stacked against someone from age three, our country is failing to deliver on the promise of equal opportunity. We need to improve schools in impoverished areas, create better support systems in all schools, and implement safety nets for at risk students earlier on to give every child the opportunity to receive the education they need to be successful. Providing educational equality offers an opportunity to escape the generational cycle of poverty and allows for upward mobility. Ultimately, solving these interrelated issues will benefit all Americans, because higher levels of education and wealth are correlated to lower rates of other social issues, such as violent crime, teenage pregnancies, and drug abuse. If we can solve these issues, we can stop the crippling cycle of poverty, make the American Dream attainable, create a more unified country and pave the way to social change worldwide.'
    },
    {
      type: 'divider'
    },
    {
      type: 'author',
      name: 'Rebecca Reynolds',
      title: 'Operations Director of Kids First Project'
    }
  ]
};

export default Blog20160920;

const AdvocacyBlog201906195 = {
  id: 201906195,
  title: "Jerome's Capitol Hill Pitch",
  type: 'text',
  publishDate: '2019-06-19',
  summary:
    'Now, I didn’t know how I’d deliver my homeless experience to men and women of your caliber. I found myself getting anxiety trying to see myself through your eyes. As usual, I imagine you see me as a lazy self-destructive millennial who looks for short term escapism in addiction while cursing President Trump and capitalism.',
  body: [
    {
      type: 'heading',
      text: 'Rise Up to the Rose Level'
    },
    {
      type: 'paragraph',
      text:
        'Now, I didn’t know how I’d deliver my homeless experience to men and women of your caliber. I found myself getting anxiety trying to see myself through your eyes. As usual, I imagine you see me as a lazy self-destructive millennial who looks for short term escapism in addiction while cursing President Trump and capitalism.'
    },
    {
      type: 'list',
      ordered: false,
      data: [
        'I never felt that the system was against me or oppressing me. A lot of people don’t trust the government, but I believe that you have earned your place here.',
        'My sense of home instability was apparent two days after my 18th birthday, when my aunt kicked me out.',
        'I was full of eager ambition to find my place in society, working odd jobs from day labor, getting my welders certification in Denver and digging pools across Phoenix.',
        'How I became homeless: I had a conflict with my family, where they wouldn’t let me follow my passions. We were unable to function together, and I had a physical fight with my father in 2017.',
        'At first I thought I was too good for shelters, so I slept outside with a sleeping bag. Throughout being homeless with irregular housing, I still worked and didn’t tell my employer that I was homeless. ',
        'I was very tired, cold, and in pain - My body felt heavy. ',
        'I stayed outside until the weather was too cold, in which I had to go to a shelter.',
        'I spent a whole year hiding my homelessness from my friends and family due to the social stigma, which was a big part of my depression and anxiety. My work added to these feelings.',
        'When I went into a shelter, I realized how little I communicated with anyone. I realized how depressed I was, and was able to get mental health support. However, the shelter was traumatic.',
        'I experienced a push and pull, where I needed people but also pushed them away.',
        'Then fueled by the curiosity of social stigma and short term heated family fallouts, I only knew this was the liberation I needed in order to find my purpose and invest in my passion.'
      ]
    },
    {
      type: 'divider'
    },
    {
      type: 'author',
      name: 'Jerome Moss',
      title: '2019 Fellow'
    }
  ]
};

export default AdvocacyBlog201906195;

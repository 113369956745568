import React from 'react';
import { Container } from 'react-bootstrap';

const NotFound = () => (
  <Container className="page-container">
    <h1>Page not found!</h1>
  </Container>
);

export default NotFound;

const Blog20160127 = {
  id: 201600127,
  title: 'Trust Women 2015 Conference: Confronting Pressing Issues of our Time',
  type: 'text',
  publishDate: '2016-01-27',
  summary:
    'I was recently part of the ANNpower Global Delegation to the 2015 Trust Women Conference in London, a conference dedicated to "putting the rule of law behind women\'s rights" with a focus on modern day slavery and human trafficking. I met global thought leaders, from Tunisia to India to Switzerland. The conference offered a chance to interact with leaders of multinational corporations, governments, and NGOs. Discussions occurred both on and off the stage, and potentially impacted the lives of thousands of people.',
  body: [
    {
      type: 'lead',
      text:
        'I was recently part of the ANNpower Global Delegation to the 2015 Trust Women Conference in London, a conference dedicated to "putting the rule of law behind women\'s rights" with a focus on modern day slavery and human trafficking.'
    },
    {
      type: 'paragraph',
      text:
        'I met global thought leaders, from Tunisia to India to Switzerland. The conference offered a chance to interact with leaders of multinational corporations, governments, and NGOs. Discussions occurred both on and off the stage, and potentially impacted the lives of thousands of people.'
    },
    {
      type: 'paragraph',
      text:
        'The importance of exposing youth like me to these kinds of events is not to be taken lightly or overlooked. The opportunity to be at such an event is an investment. As Redeat Gebeyehu, a fellow WiSci camper and a WiSci Youth Ambassador at the recent UNESCO Youth Forum in Paris, has written, "Investing in youth is investing in our future." Not only is the youth perspective important, the world needs youth to be present at events like Trust Women because such experiences can accelerate our understanding and knowledge. We do not yet have strong formal commitments to governments or corporations, and thus we are free to soak up information and learn as much as we can on an independent basis.'
    },
    {
      type: 'paragraph',
      text:
        'The conference was held immediately following the terrorist attacks in Kano, Beirut, and Paris and there was great focus on the role of women in countering violent extremism. There was a panel devoted to discussing women under extremist rule, from ISIS to Boko Haram. We learned how ISIS recruits girls and women, beyond the push factors associated with Western estrangement, the pull factors of purpose and meaning for disaffected youth living in the West. To quote from the panel, "if we don\'t give them a reason for life, ISIS will give them a reason for death."'
    },
    {
      type: 'paragraph',
      text:
        'In London, I had the opportunity to speak with Fiona Mahvinga, a co-founder of the Camfed (Campaign for Female Education) Network. Camfed is a pan-African network of educated women that is helping to empower the next generation of African female leaders. More than 190,000 girls have been supported through Camfed. Combined with the force of the African Leadership Academy, as well as other leadership programs throughout the continent, there is most certainly a tide of strong African leadership that continues to grow stronger. This up and coming generation of African leadership represents how "Africa for the Africans" can now truly be realized.'
    },
    {
      type: 'paragraph',
      text:
        'It is important to recognize who is making the decisions for whom. Those seeking to combat issues such as modern day slavery and gender-based violence are often coming from a place of privilege, which can translate to distancing from the realities of these issues. At Trust Women, human trafficking was not discussed from such usual distances. We heard directly from survivors, from Ghana to Japan to the United States. When we learned about the Yazidi genocide, we learned about it from a Yazidi man who came to the conference seeking funds and partners for an organization he runs supporting Yazidis. Gratefully, he was successful in both efforts. When we talked about disaffected youth in the West joining ISIS, the discussion was led by a mother who had lost her son to ISIS. She has since formed a network of other mothers who have lost their children to ISIS. Thus, Trust Women offered firsthand accounts of the topics we discussed, and provided an incredible first-hand look at what is being done to confront these pressing issues of our time.'
    },
    {
      type: 'divider'
    },
    {
      type: 'author',
      name: 'Rara Williams',
      title: 'WiSci STEAM Camp participant',
      biography:
        "Rara Williams is 17 years old and lives in Athens, GA. She is a 2014 ANNpower Fellow and Grantee, and founder of Athens Art Project, an arts program for youth in Athens' federal housing projects. She loves roots reggae music and is interested in pursuing a career in international affairs and economics."
    }
  ]
};

export default Blog20160127;

import headerImage from '../../assets/images/blog/2015-09-19-1.jpg';
import headerImage2 from '../../assets/images/blog/2015-09-19-2.jpg';
import headerImage3 from '../../assets/images/blog/2015-09-19-3.jpg';
import headerImage4 from '../../assets/images/blog/2015-09-19-4.jpg';
import headerImage5 from '../../assets/images/blog/2015-09-19-5.jpg';

const headerImages = [
  headerImage5,
  headerImage,
  headerImage2,
  headerImage3,
  headerImage4
];

const Blog20150919 = {
  id: 20150919,
  title: '2015 NCAM Youth Ambassadorship: A Week to Remember',
  type: 'text',
  publishDate: '2015-09-19',
  headerImages,
  summary:
    '“You aren’t the hope of tomorrow, the world needs the help from youth today.” The words from Steve Culbertson, CEO of Youth Service America, rang true for the 51 youth ambassadors sitting in the large conference room of Pinstripes, all of whom were there for similar reasons: to represent their passion and state.',
  body: [
    {
      type: 'lead',
      text:
        '“You aren’t the hope of tomorrow, the world needs the help from youth today.”'
    },
    {
      type: 'paragraph',
      text:
        'The words from Steve Culbertson, CEO of Youth Service America, rang true for the 51 youth ambassadors sitting in the large conference room of Pinstripes, all of whom were there for similar reasons: to represent their passion and state.'
    },
    {
      type: 'paragraph',
      text:
        'This week, I had the opportunity of being Oregon’s NCAM Youth Ambassador. With this privilege, Youth Service America and the Festival of Children Foundation has generously granted Kids First Project one thousand dollars as well as a platform for our mission. With the excitement of meeting the entire country (one representative per state, including D.C.) and to be back in the east coast, I packed my bags and headed off on another adventure.'
    },
    {
      type: 'paragraph',
      text:
        'If I had to give three adjectives to describe the program, I would use informative, inspiring, and ingenious. Informative. The training raised countless topics that I had never even considered. Topics such as viral fundraising, measurable outcomes, mentors, and branding were all demystified and presented to us. Although my group’s attempt to connect with Ellen Degeneres through our viral fundraising plan failed, we ended up learning many tips from the CEO of Good World. Furthermore, the Executive Director of Michelle Obama’s “Let’s Move!” campaign came and spoke to us about working in the white house, how to follow your passion, and to treat everybody with respect, because “everybody deserves the same level of attention.” From speakers coming from UNICEF, Food Recovery Network, and Truth Initiative, we all learned invaluable information to bring back to our communities.'
    },
    {
      type: 'paragraph',
      text:
        'Inspiring. From the Youth Service America people coordinating the program to the speakers from around the world to the 50 other youth ambassadors, the four days were undoubtedly inspiring. I met a sixteen year old boy who founded a nonprofit called Technocademy, which has served over 150, 000 people, as well as a high school senior who is pushing for legislation to be passed in her state to help those who aren’t receiving adequate education. I came home with a stronger driving force, having seen so many changemakers who have made their visions a reality.'
    },
    {
      type: 'paragraph',
      text:
        'Ingenious. A platform to raise the voices of youth around the world? Again, ingenious. On the last day of the training, we were sent to Capitol Hill to present our elevator pitches to our state legislature. Although I did not end up meeting Ron Wyden, I was able to present Kids First Project to his staff member. There is something especially empowering and unique about being a teenager walking through the halls of Capitol Hill, the same place where our state’s policy makers and advocates walk. During that time, I knew my voice was not only heard, but also valued, and that is the key to being truly heard.'
    },
    {
      type: 'paragraph',
      text:
        'Equipped with a new network and valuable training, YSA and Festival of Children Foundation has sent us all back home to celebrate National Child Awareness Month, which is September. I not only pledge to raise awareness for children’s issues all of this month, but I also pledge to carry out Global Youth Service Day here in Oregon. With the help of this program, the Kids First Project team plans to carry out our GYSD event this April with a 5k charity run and community panel on poverty, youth, and education. Words cannot describe how grateful the Kids First Team and I are for having this life changing opportunity - Thank you Youth Service America and Festival of Children Foundation! #NCAMyouth'
    },
    {
      type: 'author',
      name: 'Alisha Zhao',
      title: 'Executive Director of Kids First Project'
    }
  ]
};

export default Blog20150919;

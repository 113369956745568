import headerImage from '../../assets/images/blog/2017-10-06.jpg';

const Blog20171006 = {
  id: 20171006,
  title: 'Board Member Spotlight: Ashley Lin, Scholarship Director',
  type: 'interview',
  publishDate: '2017-10-06',
  headerImage,
  summary: "Read our interview with Kids First Project's Scholarship Director.",
  body: [
    {
      type: 'strong',
      text: 'Why did you join Kids First Project?'
    },
    {
      type: 'paragraph',
      text:
        'I joined the Kids First Project because I have recently realized the impact financial obstacles create on low-income, under-represented, and Title 1 students. I have found my passion in bridging the opportunity gap for students from different socioeconomic sectors, and I believe all youth should have access to the resources and support needed to go for their wildest dreams. As the oldest child in a family of first generation immigrants, I understand what it is like to create your own doors, offering up only your potential and determination, and have those same doors be slammed in your face. My hope is to empower all students to be brave enough to embrace what they can do, and to wield their passion in one hand and ambition in the other.'
    },
    {
      type: 'strong',
      text: "What is something that you can't go a day without?"
    },
    {
      type: 'paragraph',
      text:
        "Something I can't go a day without is talking with someone from my Future Business Leaders of America (FBLA) state officer team. A part of that might be because if I didn't talk to them, nothing would be able to get done; however, a bigger part of that is because the state officer and management team has become like a second family to me. I can't imagine being who I am today without FBLA, and I look forward to helping many more students discover the competitiveness and camaraderie I have found within FBLA!"
    },
    {
      type: 'strong',
      text: 'What is one of your biggest goals?'
    },
    {
      type: 'paragraph',
      text:
        'One of my biggest goals is to start a social enterprise providing a curriculum and the funds for under-served, under-represented, and minority students to catalyze positive social change. I envision a social incubator where students work with fiscal sponsors to put their service idea into action, and creates a youth advisory board to support the idea. Each youth advisory board member from each service idea would then fundraise $50 yearly going back to to social enterprise, and help other youth empower youth!'
    },
    {
      type: 'strong',
      text: 'Where do you see yourself in 10 years?'
    },
    {
      type: 'paragraph',
      text:
        "Even though I'm not sure what career path I would like to take after college, I see myself going to Wharton and double majoring in international business and corporate law, with a minor in political science. I've also caught the entrepreneurship bug early on, so I hope to find a way to bring that along with my love for civics and law together to drive social change within my community."
    },
    {
      type: 'strong',
      text:
        'You have an entire day to yourself. Where do you go and what will you do?'
    },
    {
      type: 'paragraph',
      text:
        "If I had a day to myself I would most likely curl up with some blankets, a book, music, and hot chocolate, and just spend the day reading. I will read anything someone puts in front of me (except those books from my English classes - that's what Sparknotes and Schmoop is for), but I especially love fantasy!"
    },
    {
      type: 'strong',
      text: 'What is something most people don’t know about you?'
    },
    {
      type: 'paragraph',
      text:
        'Something most people don\'t know about me is that I prefer to wear heels over flats any day! I also live by the quote "Well-behaved women seldom make history", so I\'m always ready to kick-butt with my heels :)'
    },
    {
      type: 'strong',
      text: 'What is your intent?'
    },
    {
      type: 'paragraph',
      text: 'Dream!'
    }
  ]
};

export default Blog20171006;

import React from 'react';

const ArtsCrafts = () => {
  return (
    <React.Fragment>
      <h3>Arts and Crafts</h3>
      <p>
        To spark creativity and passion is to create a whole new perspective for
        a child. With our arts and crafts activities, children will be able to
        explore the world of colors and endless ideas to release stress and
        promote imagination.
      </p>
    </React.Fragment>
  );
};

export default ArtsCrafts;

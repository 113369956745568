const Blog20171007 = {
  id: 20171007,
  title: 'A Letter to United Nations Ambassador Haley',
  type: 'letter',
  publishDate: '2017-10-07',
  summary: "Read Alisha's letter to United Nations Ambassador Haley.",
  body: [
    {
      type: 'paragraph',
      text: 'Dear Ambassador Haley,'
    },
    {
      type: 'paragraph',
      text:
        'You do not know me, but I serve on Plan International USA’s Youth Advisory Board and met with two representatives from your staff on Tuesday, July 11th during the United Nations High-Level Political Forum (UN HLPF). I appreciate that your staff was gracious enough to meet with me but was told that had I (instead of the adult staff from Plan International) reached out to your office, my meeting request would have been denied. As I believe youth voices are important, I was disappointed to hear that.'
    },
    {
      type: 'paragraph',
      text:
        'Therefore, I am writing to you with a list of proposals, with the hope that your office will change its perspective on youth and seriously consider the valuable insights that we bring to the table.'
    },
    {
      type: 'paragraph',
      text:
        '1. Create an official committee for more youth in the U.S. to engage in the work of the UN alongside key stakeholders and decision makers. '
    },
    {
      type: 'paragraph',
      text:
        'One young person’s background and perspectives do not account for all the youth in a single community, country, or world. When I inquired about increasing youth participation at the UN in the U.S., I was told that beyond the lone U.S. Youth Observer, there are plenty of “youth” who work in the government and young people should vote if they wish to be heard. While civic engagement is indisputably important, not everybody has the privilege to vote, whether it is due to citizenship barriers or age. Another consideration is that by only listening to those who are already involved in the work of the UN and related areas, we are turning a blind eye to youth who do not have such a platform. Youth who experience homelessness, for instance, are often not heard. When we are working on Sustainable Development Goals (SDGs) like No Poverty and Zero Hunger, we need to hear from the young people directly affected.'
    },
    {
      type: 'paragraph',
      text: '2. Climate change matters.'
    },
    {
      type: 'paragraph',
      text:
        'The U.S. hosted the HLPF in New York and invited government officials from all around the world to come and submit reports, yet we failed to submit a sustainability report ourselves. We need to hold ourselves accountable, acknowledge that climate change is a pressing issue (hence all the SDGs related to the environment), and lead by example. Among all the delegates I met from countries like India, Chile, and Brazil, climate change was an issue about which we all felt passionate—and it needs urgent attention. If we do not take responsibility for the future of our home right now, it will be impossible to move forward with the 2030 Agenda because poverty and sustainability directly intersect with human-caused climate change.'
    },
    {
      type: 'paragraph',
      text: '3. Youth are NOW, not the future. Please lend an active ear. '
    },
    {
      type: 'paragraph',
      text:
        'Ultimately, if we wish to create a UN that is truly inclusive and effective, youth and our unique passions and views must be welcomed and heard at the table. There are youth who speak out and act against the human rights abuses in their respective communities every single day, yet their governments stifle their voices with threats and physical violence. They need to be heard. '
    },
    {
      type: 'paragraph',
      text:
        'We, just like you, want to create a more equitable world and have our world’s best interests in mind. Please seriously consider turning these words into action. I look forward to staying updated and involved with your work on the 2030 Agenda.'
    },
    {
      type: 'paragraph',
      text: 'Sincerely,'
    },
    {
      type: 'paragraph',
      text: 'Alisha'
    },
    {
      type: 'paragraph',
      text: 'Plan International USA Youth Advisory Board Member'
    }
  ]
};

export default Blog20171007;

const AdvocacyBlog201906194 = {
  id: 201906194,
  title: "Haven's Capitol Hill Pitch",
  type: 'text',
  publishDate: '2019-06-19',
  summary:
    'My name is Haven Thompson. I am a 19 year old trans man. I started my transition in August of 2017. I have been homeless since 2016 and I am still trying to this day to get stable housing.',
  body: [
    {
      type: 'paragraph',
      text:
        'My name is Haven Thompson. I am a 19 year old trans man. I started my transition in August of 2017. I have been homeless since 2016 and I am still trying to this day to get stable housing.'
    },
    {
      type: 'paragraph',
      text:
        'I was housed until I got kicked out because I was defending a friend of mine from transphobic people. The program I was in when I was housed was New Avenues for Youth.'
    },
    {
      type: 'paragraph',
      text:
        "I am really passionate about advocating for people because that's what the world needs and I will do everything in my power to do whatever I can to help people in need. That's my whole mission for this DC trip to make my name and my story known and hopefully Senator Wyden and Senator Merkley will do something about the issues that the LGBTQIA+ community and people of color go through on a daily basis. Examples: Discrimination, racism, oppression, etc."
    },
    {
      type: 'divider'
    },
    {
      type: 'author',
      name: 'Haven Thompson',
      title: '2019 Fellow'
    }
  ]
};

export default AdvocacyBlog201906194;

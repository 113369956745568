import headerImage from '../../assets/images/blog/2015-08-05.jpg';

const Blog20150805 = {
  id: 20150805,
  title: 'Website is Officially Up!',
  type: 'text',
  publishDate: '2015-08-05',
  headerImage,
  summary:
    'After weeks of late nights and coffee runs, our mission video and website are finally complete. Now that we have all our grant proposals submitted, bank account set up, incorporation papers completed, and networks created, we are ready to put months worth of ideas and planning into action. Last Thursday, we held our first advisory board meeting. We were able to connect our ideas for networking, management, and sustainability together to create a layout for the months to come. The diversity and talents of our advisory board will undoubtedly be an asset to the organization.',
  body: [
    {
      type: 'lead',
      text:
        'After weeks of late nights and coffee runs, our mission video and website are finally complete.'
    },
    {
      type: 'paragraph',
      text:
        'Now that we have all our grant proposals submitted, bank account set up, incorporation papers completed, and networks created, we are ready to put months worth of ideas and planning into action. Last Thursday, we held our first advisory board meeting. We were able to connect our ideas for networking, management, and sustainability together to create a layout for the months to come. The diversity and talents of our advisory board will undoubtedly be an asset to the organization.'
    },
    {
      type: 'paragraph',
      text:
        'Our executive director, Alisha Zhao, will be headed to D.C. in September to attend the NCAM Youth Ambassador leadership forum where she will be representing Oregon. Besides receiving a $1000 grant courtesy of Youth Service America and the Festival of Children Foundation, she will also have the privilege to meet with Oregon’s senators and representative in Capitol Hill and take home new skills to bring to Kids First Project.'
    },
    {
      type: 'paragraph',
      text:
        'Please stay tuned for our official implementation date in September!'
    },
    {
      type: 'paragraph',
      text:
        'Quote of the week: “Be the change you want to see in the world.” - Gandhi'
    }
  ]
};

export default Blog20150805;

import headerImage from '../../assets/images/blog/2015-08-30-1.jpg';
import headerImage2 from '../../assets/images/blog/2015-08-30-2.jpg';
import headerImage3 from '../../assets/images/blog/2015-08-30-3.jpg';
import headerImage4 from '../../assets/images/blog/2015-08-30-4.jpg';
import headerImage5 from '../../assets/images/blog/2015-08-30-5.jpg';

const headerImages = [
  headerImage,
  headerImage2,
  headerImage3,
  headerImage4,
  headerImage5
];

const Blog20150830 = {
  id: 20150830,
  title: '2015 NCAM Youth Ambassadorship: A Week to Remember',
  type: 'text',
  publishDate: '2015-08-30',
  headerImages,
  summary:
    'Recently, we received a generous donation of five hundred dollars from Joan and John Bollier, both of whom are new volunteers at PHFS, to purchase back-to-school supplies for the kids in shelter. Knowing how empowering and exciting it is to start the school year off with a new backpack, shining school supplies, and outfit, our team quickly set out to gather all the families wishlists.',
  body: [
    {
      type: 'lead',
      text:
        'Recently, we received a generous donation of five hundred dollars from Joan and John Bollier, both of whom are new volunteers at PHFS, to purchase back-to-school supplies for the kids in shelter.'
    },
    {
      type: 'paragraph',
      text:
        'Knowing how empowering and exciting it is to start the school year off with a new backpack, shining school supplies, and outfit, our team quickly set out to gather all the families wishlists.'
    },
    {
      type: 'paragraph',
      text:
        'From “Frozen” backpacks to colorful binders to “anything Thomas the Train or Cars”, we spent the weekend scavenging through the back to school sections of Old Navy, Ross, and Target.'
    },
    {
      type: 'paragraph',
      text:
        'A special thank you also has to go out to Lannette Hummel for supporting us by donating additional backpacks and fun pencil toppers for the kids! After writing personalized cards and filling each backpack to the zipper with each child’s wish list items, a few of us were able to head out the same night to deliver the backpacks. Words cannot describe how joyful and appreciative the children were - It could definitely be compared to Christmas morning.'
    },
    {
      type: 'paragraph',
      text:
        'We are so thankful to call this project the official first event for Kids First Project. Although we don’t officially start program nights until October 1st, it was nice to be with the families and children, many of which are starting their first day of kindergarten this week!'
    }
  ]
};

export default Blog20150830;

const AdvocacyBlog201906193 = {
  id: 201906193,
  title: "Norena's Capitol Hill Pitch",
  type: 'text',
  publishDate: '2019-06-19',
  summary:
    "My name is Norena, I'm nineteen years old, and my family and I were homeless in Portland, Oregon, when I was 14 years old. This was during the summer before my freshman year of high school.",
  body: [
    {
      type: 'paragraph',
      text:
        "My name is Norena, I'm nineteen years old, and my family and I were homeless in Portland, Oregon, when I was 14 years old. This was during the summer before my freshman year of high school."
    },
    {
      type: 'paragraph',
      text:
        'My family and I rented a food cart on the corner of Williams and Shaver in North Portland, and business was very successful until winter came around. During the winter, business was very slow and due to my family not being financially prepared to support all the bills, not only did we lose our food truck but we lost our home as well.'
    },
    {
      type: 'paragraph',
      text:
        'We ended up living in our `79 Chevy Impala, until I managed to get a hold of Portland Catholic Charities. They connected us with Laura Berrutti, a social service volunteer, who helped my family through so much, she managed to help my family get into the Portland Housing Family Solutions, Goose Hollow Shelter.'
    },
    {
      type: 'paragraph',
      text:
        "Although this was a very difficult and stressful situation, I don't regret a minute of it because for me, being homeless has been an experience and opportunity to grow as a person. It has opened my eyes up to the reality of homelessness and how easy it can be for anyone to lose their home. The volunteers and workers at PHFS provided my family and I with a secure shelter, while we were trying to get ourselves back up on our feet and we are very thankful to have had all their support."
    },
    {
      type: 'paragraph',
      text:
        "The lack of affordable housing is the biggest issue to homelessness in Portland, and it needs to be resolved. With constant renovations and gentrified Portland neighborhoods, it's important that we do something to continue to fund Housing and Urban Development so that our youth and families continue to have support. It is also important that the HB 2015 gets signed and passed so that we can make driving licenses accessible to all."
    },
    {
      type: 'divider'
    },
    {
      type: 'author',
      name: 'Norena Arias',
      title: '2019 Fellow'
    }
  ]
};

export default AdvocacyBlog201906193;

const AdvocacyBlog20190628 = {
  id: 20190628,
  title: "Baylee's Post D.C. Reflection",
  type: 'text',
  publishDate: '2019-06-28',
  summary:
    'On June 20th, 2019 I was given an opportunity to speak about something that affected me and thousands of other men and women in the United States.',
  body: [
    {
      type: 'paragraph',
      text:
        'On June 20th, 2019 I was given an opportunity to speak about something that affected me and thousands of other men and women in the United States.'
    },
    {
      type: 'paragraph',
      text:
        'I spent two days prior to leaving for Washington DC in workshops. In these workshops we learned about effective advocacy, public policy, and public speaking. We also in those workshops had the opportunity to meet people doing extraordinary things in the state of Oregon for the advocacy of homeless individuals. We got to know one another and everyone’s stories in this setting, making it easier to encourage and uplift each other while in Washington DC.'
    },
    {
      type: 'paragraph',
      text:
        'On our first night in DC, we all explored the city together, getting lost while trying to find the White House. We stopped to take pictures a lot and bonded in the humid heat. When we eventually made it to the White House we found a protest across the street that had been there for 38 years, protesting everything from elephant poaching to war. Later that night we worked on our pitches as a team giving advice and help where needed.'
    },
    {
      type: 'paragraph',
      text:
        'The next day was our meetings at the Capitol or, “The Hill”.  Our first meeting was with Senator Merkley, and his staff. This meeting shook the nervousness out of us and made us more prepared for our meeting with Senator Wyden. We also got a tour of the Capitol building where we saw The United States’ history and a lot of The Hart Building. At Senator Wyden’s office we shared our stories as well as asked lots of questions about what we were looking for in our pitches. Our last meeting that day was with The National Law Center on Homlessness and Poverty, where we learned about what they did and the change they’re inspiring. After that we had a celebratory dinner at founding fathers and had GeorgeTown Cupcakes for dessert. Knowing it was our last day even though we were exhausted me and another fellow went out on one last adventure to take in the city and found beautiful buildings and monuments. She also got to hold her first firefly. '
    },
    {
      type: 'paragraph',
      text:
        'On our last day we did a tour of the monument mall. And then got the opportunity to explore once more. We got to see the free Iran protest as well as the annual congressional picnic. Walking around and taking in the culture and the people around Washington DC. I was sad to go as we left for the airport but I was ready to go home and share the stories of what I learned with the people that I was advocating for. '
    },
    {
      type: 'paragraph',
      text:
        'This trip to Washington DC was life changing to say the least. I got to see the world in a different way.I learned how to take a terrible thing that happened to me and make it a positive thing to encourage change for others in my situation. I got a lot of self confidence and pride in who I am and what I’ve been through. There’s a lot of power and strength in that, and I cannot express my gratitude to Kids First Project for giving us this opportunity to change our lives for the better. You gave us a better outlook and vision for the things that happen to us and you gave us the chance to help others who have and will go through what we went through. That’s an amazing and beautiful thing and I am so thankful and will not ever forget this trip and the things I did.'
    },
    {
      type: 'divider'
    },
    {
      type: 'author',
      name: 'Baylee Clark',
      title: '2019 Fellow'
    }
  ]
};

export default AdvocacyBlog20190628;

import {
  faFileAlt,
  faComments,
  faEnvelope
} from '@fortawesome/free-solid-svg-icons';

const iconMap = {
  text: faFileAlt,
  interview: faComments,
  letter: faEnvelope
};

export default iconMap;

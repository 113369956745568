import headerImage from '../../assets/images/blog/2017-04-18.jpg';

const Blog20170418 = {
  id: 20170418,
  title: 'Volunteer of the Month: Sydney Buffonge',
  type: 'interview',
  publishDate: '2017-04-18',
  headerImage,
  summary:
    "Sixteen and a current student at St. Mary's Academy, Sydney brings an endless amount of enthusiasm to our program nights at PHFS. Read on to learn more about her work with Kids First Project, her awesome Miniature Schnauzer, and her adventures in the beautiful PNW!",
  body: [
    {
      type: 'lead',
      text:
        "Sixteen and a current student at St. Mary's Academy, Sydney brings an endless amount of enthusiasm to our program nights at PHFS. Read on to learn more about her work with Kids First Project, her awesome Miniature Schnauzer, and her adventures in the beautiful PNW!"
    },
    {
      type: 'divider'
    },
    {
      type: 'strong',
      text: 'What does Kids First Project mean to you?'
    },
    {
      type: 'paragraph',
      text:
        'I have volunteered at the Oregon Humane Society for the past several years, and have enjoyed it immensely. Through my work there, I have developed a true passion for service. However, I wanted an opportunity to work more directly with people, rather than just animals. After doing some research, Kids First Project seemed like the ideal option, as I have had a lot of babysitting experience and enjoy spending time with kids. I was immediately welcomed into the KFP community, and I feel very lucky to be a part of it!'
    },
    {
      type: 'strong',
      text: 'What is your favorite memory while volunteering with us?'
    },
    {
      type: 'paragraph',
      text:
        'During my first KFP program night, we taught the kids how to construct catapults out of popsicle sticks, with the help of an informative blog post. It was incredibly heartwarming to witness the genuine excitement of all of the children, upon seeing the catapults that we had built together launch the colorful pom poms into the air. It was not just fun— it was also an unexpected learning experience for all of us, because we ran a number of trials to determine which launch techniques worked best, height-wise and distance-wise.'
    },
    {
      type: 'strong',
      text: "What is something that you can't go a day without?"
    },
    {
      type: 'paragraph',
      text:
        'Spending time with my Miniature Schnauzer, Rigsby, who is quite the little spitfire.'
    },
    {
      type: 'strong',
      text: "What is something that you can't go a day without?"
    },
    {
      type: 'paragraph',
      text:
        'Spending time with my Miniature Schnauzer, Rigsby, who is quite the little spitfire.'
    },
    {
      type: 'strong',
      text: 'How many trees are there in Portland?'
    },
    {
      type: 'paragraph',
      text:
        'A wonderfully large amount. If I had to guess, I would assume that there are well over 1 million.'
    },
    {
      type: 'strong',
      text:
        'You have an entire day to yourself. Where do you go and what will you do?'
    },
    {
      type: 'paragraph',
      text:
        'I love to take advantage of the numerous outdoor activities that Oregon has to offer. If I had a day to do whatever I wanted, I would go pick up two of my closest friends, Libby and Lea, and drive us all out to the Columbia River Gorge. On our way there, we would jam out, by listening to my Spotify road trip playlist. We would then spend the entire day going from trail to trail, exploring the countless waterfalls, and taking pictures to document our adventures.'
    },
    {
      type: 'strong',
      text: 'What is your intent?'
    },
    {
      type: 'paragraph',
      text: 'Enthusiasm.'
    }
  ]
};

export default Blog20170418;

const AdvocacyBlog20190709 = {
  id: 20190709,
  title: "Haven's Post D.C. Reflection",
  type: 'text',
  publishDate: '2019-07-09',
  summary:
    'All in all, the trip was amazing. I got my word out to the Senators, got to tour around DC, met some amazing folks, and made lifelong memories that I’ll never forget. It was truly worth it and I’d do it all over again if I had to!',
  body: [
    {
      type: 'paragraph',
      text:
        'I have to say all the workshops were very interesting and good in their own way. But I have to say my favorite one was from Antoinette. I wasn’t really great or confident in myself talking with people or to people about my story until Antoinette came in and changed my whole perspective. She made me go over it three or four times in front of people at the workshop and if I wasn’t quite confident enough to her, she would say to me that I have to sit up straight and read my favorite line as loud and proud as I can. She was just an amazing person. I really enjoyed her and her visit! '
    },
    {
      type: 'paragraph',
      text:
        'Now... The Washington, D.C. trip was amazing! We all got to meet with Senator Merkley and Senator Wyden at Capitol Hill to tell our stories. I really enjoyed meeting the both of them. But I think meeting Wyden was amazing for me, personally. I told him my story and how I’m still homeless to this day, and I had nowhere to go when I got back into Portland. He showed a lot of concern. He told me that he wanted all of my contact information and that he was going to, under promise, ensure that I was going to have a place to go back when I got home, and I did! '
    },
    {
      type: 'paragraph',
      text:
        'I think my all time favorite memory though was honestly just exploring around DC. Getting lost, looking at the White House, and touring the Capitol was beyond amazing! We also had Georgetown Cupcakes which was amazing! We also went to the famous Founding Farmers in DC and their food was so good!'
    },
    {
      type: 'paragraph',
      text:
        'All in all, the trip was amazing. I got my word out to the Senators, got to tour around DC, met some amazing folks, and made lifelong memories that I’ll never forget. It was truly worth it and I’d do it all over again if I had to!'
    },
    {
      type: 'divider'
    },
    {
      type: 'author',
      name: 'Haven Thompson',
      title: '2019 Fellow'
    }
  ]
};

export default AdvocacyBlog20190709;

import React from 'react';

const LifeSkills = () => {
  return (
    <React.Fragment>
      <h3>Life Skills Classes</h3>
      <p>
        We provide fun, interactive classes on skills to promote the well being
        and health of our children, such as classes on treating your body right
        through healthy eating and exercise, stress relieving exercises, and
        civic and government classes from Lincoln’s Constitution Team.
      </p>
    </React.Fragment>
  );
};

export default LifeSkills;

import headerImage from '../../assets/images/blog/2015-11-01-1.jpg';
import headerImage2 from '../../assets/images/blog/2015-11-01-2.jpg';
import headerImage3 from '../../assets/images/blog/2015-11-01-3.jpg';
import headerImage4 from '../../assets/images/blog/2015-11-01-4.jpg';
import headerImage5 from '../../assets/images/blog/2015-11-01-5.jpg';
import headerImage6 from '../../assets/images/blog/2015-11-01-6.jpg';
import headerImage7 from '../../assets/images/blog/2015-11-01-7.jpg';
import headerImage8 from '../../assets/images/blog/2015-11-01-8.jpg';

const headerImages = [
  headerImage5,
  headerImage,
  headerImage2,
  headerImage3,
  headerImage4,
  headerImage6,
  headerImage7,
  headerImage8
];

const Blog20151101 = {
  id: 20151101,
  title: 'The First Week of Program Nights',
  type: 'text',
  publishDate: '2015-11-01',
  headerImages,
  summary:
    'Our first program night was October 22nd with six volunteers, ranging from all ages. We had a lot of fun playing tag and games, and making art with the PHFS kids.',
  body: [
    {
      type: 'lead',
      text:
        'Our first program night was October 22nd with six volunteers, ranging from all ages. We had a lot of fun playing tag and games, and making art with the PHFS kids.'
    },
    {
      type: 'paragraph',
      text:
        'Happy Halloween! Our next program night was carving pumpkins and decorating bags with the PHFS families. It was a great bonding activity and the kids were excited to celebrate the holiday. Thanks for all of the volunteers who came to help out!'
    },
    {
      type: 'paragraph',
      text:
        'An extension to Halloween, a group of volunteers and PHFS kids ventured up to the Oregon Zoo on Halloween day. We rode the TriMet train up and back, wandered through the animals’ exhibits, participated in the Oregon Zoo’s scavenger hunt, and got a “little” wet in the rain!'
    },
    {
      type: 'author',
      name: 'Kerry Horton',
      title: 'Director of Communications'
    }
  ]
};

export default Blog20151101;

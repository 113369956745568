const AdvocacyBlog201906192 = {
  id: 201906192,
  title: "Baylee's Capitol Hill Pitch",
  type: 'text',
  publishDate: '2019-06-19',
  summary:
    'On July 2, 2018, my fiance broke up with me in a hospital after I had a mental health crisis. After almost a year of physical and mental abuse, I was finally free. We both had been suffering from mental health issues and drug addictions. However, I had moved across the country for him so I had nowhere to go after being released from the hospital, so the social worker I had working with me sent me to a homeless shelter for youth under 25 called Porchlight.',
  body: [
    {
      type: 'paragraph',
      text:
        'On July 2, 2018, my fiance broke up with me in a hospital after I had a mental health crisis. After almost a year of physical and mental abuse, I was finally free. We both had been suffering from mental health issues and drug addictions. However, I had moved across the country for him so I had nowhere to go after being released from the hospital, so the social worker I had working with me sent me to a homeless shelter for youth under 25 called Porchlight.'
    },
    {
      type: 'paragraph',
      text:
        "That first night they called a crisis night and told me that I had to do a longer intake into the homeless youth continuum the next morning at nine. The morning of the third, I had to leave early to do an intake for mental health outpatient, but the shelter told me if I didn't make it back in time to their intake I wouldn't be able to stay there that night. This put extreme pressure on me I was dealing with the fact that my fiance had just left me with nowhere to go, I was in a very bad place mentally, and I was trying to understand the abuse I had been dealing with from him, and now I have to wonder if I'm going to have anywhere to stay that night. No one was telling me any resources for domestic violence survivors even after me asking for those specifically. I ended up making it back in time and i was given a space place to stay. However, even with me asking for it for months I was never provided with help specifically for domestic violence. Most resources needed hospital or police records, none of which I had due to the love and fear of my abuser at the time of abuse. He had told me that no one would ever believe me or care if I spoke out about what he was doing. When I was brought into the shelter I was at a breaking point and told them everything that had been happening and it felt that I wasn’t getting the resources I needed for my situation. I was very broken when I entered the homeless youth continuum. At this time I was still abusing drugs as a way to self medicate for the mental scars I was left with. I didn’t know of any other way to cope. I felt that getting high was the only way to handle what I had been through."
    },
    {
      type: 'paragraph',
      text:
        'It took me two months of being homeless to work on getting sober, and even when I began working on that I had a hard time living around people who were constantly abusing drugs around me. There were attempts that didn’t last longer than a day. It took five attempts for me to finally get into recovery seriously, at that point I had to cut off people I considered friends and find people in the shelters that were also getting sober to avoid a relapse. It took me four months to find a therapist who worked with the homeless, to process everything I had been through. I still hurt from everything this man had said and done to me. Looking back I think of the fact that he made me hide bruises and believe that there was nothing I could do about it because no one would believe me.'
    },
    {
      type: 'paragraph',
      text: 'He would say, “Nobody could believe or care about an addict.”'
    },
    {
      type: 'paragraph',
      text:
        'I was lying to people that I loved telling them that I was fine and happy with him when inside I was dying. We were posting pictures on social media that made us look like the “perfect couple” only showing the happy times in our relationship. Never mentioning the times when he was high and took his anger out on me. In November I ended up having to get a restraining order, on an unrelated problem, which was the first time I was introduced to a domestic violence center and that was four months after the fact and didn’t really need the specific resources they provided that much help at that point.'
    },
    {
      type: 'paragraph',
      text:
        "When visiting the senators I want to discuss funding more domestic violence centers so no one has to experience what I did of getting the run-around. If we get more funding for centers to help those who have been told by abusers that they have no voice and no one will listen to them, victims may be more likely to voice what's happening in their own home when someone makes it unsafe. Everyone deserves to have a sense of safety in the place they live. This issue for some is a life or death issue, this is not something we can afford to keep sweeping under the rug and not discussing. We need to bring light to the reality that relationships aren’t always what they seem, not everyone is as safe as they may let on. We need change, we need funding for these programs so we can educate those on their options and the help that they can receive. We need to continue to offer help to victims so they can grow and become a survivor, and find the strength that they have within them."
    },
    {
      type: 'divider'
    },
    {
      type: 'author',
      name: 'Baylee Clark',
      title: '2019 Fellow'
    }
  ]
};

export default AdvocacyBlog201906192;

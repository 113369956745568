import headerImage from '../../assets/images/blog/2018-05-14.jpg';

const Blog20180514 = {
  id: 20180514,
  title: 'April 2018 Field Trip: Oregon Zoo',
  type: 'text',
  publishDate: '2018-05-14',
  headerImage,
  summary:
    'On April 7th, 2018, I, along with three other teen volunteers from across the Portland-metro area, had the opportunity to take three families from the PHFS 13th Salmon shelter to the Oregon Zoo. Despite torrential rain, the field trip was a clear success!',
  body: [
    {
      type: 'lead',
      text:
        'On April 7th, 2018, I, along with three other teen volunteers from across the Portland-metro area, had the opportunity to take three families from the PHFS 13th Salmon shelter to the Oregon Zoo. Despite torrential rain, the field trip was a clear success!'
    },
    {
      type: 'paragraph',
      text:
        'While our volunteers, children, and parents have vastly different backgrounds, ages, and interests, there was a shared natural curiosity and excitement for seeing all of the animals. As we gathered around the entrance of the shelter (waiting for all of the volunteers to arrive), most of the kids had brought stuffed animals and shouted out which animals they were most excited to see. Once everyone had arrived, we trekked to the Max and hopped on the Red Line, taking us all the way to the zoo.'
    },
    {
      type: 'paragraph',
      text:
        'Luckily, one of the moms had a great amount of experience at the zoo and set out a strong game plan for the order of exhibits that we would see. Most likely because of the gloomy weather, the zoo was largely empty, allowing the kids to have a front-and-center view of all of the animals. As we made our way from the orangutans, to the fish, to the penguins, to the lions, to the elephants, the kids’ eyes continued to light up with each new sight. The mom who gave us the plan also had ample knowledge on every single exhibit, providing a fantastic ecological lesson for all of the kids.'
    },
    {
      type: 'paragraph',
      text:
        'One of my favorite memories from the trip was when we visited the monkey cage and all of the younger kids started to scream with excitement as the monkeys sprung from pole to pole. The pure joy was incredibly uplifting.'
    },
    {
      type: 'paragraph',
      text:
        'Overall, the field trip was a success. Many of the families even came up after the expedition to ask when the next field trip would be. At the end of the day, I hope that all of the kids had the opportunity to experience and learn about wildlife that they’ve never seen before. Next stop: OMSI!'
    },
    {
      type: 'divider'
    },
    {
      type: 'author',
      name: 'Malcolm Asher',
      title: 'Operations Director'
    }
  ]
};

export default Blog20180514;

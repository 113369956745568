const Blog20170113 = {
  id: 20170113,
  title: 'Student Work from Poeteen’s Poetry Workshops',
  type: 'text',
  publishDate: '2017-01-13',
  summary:
    'Poetry created by youth staying at New Avenues for Youth, compiled by Sophia Mautz, one of our Volunteer Leaders.',
  body: [
    {
      type: 'heading',
      text: 'Poem 1'
    },
    {
      type: 'paragraph',
      text: 'Ask yourself: how do you know what you know?'
    },
    {
      type: 'paragraph',
      text:
        'How do you know about the moss that clings to the sea-sprayed rock?'
    },
    {
      type: 'paragraph',
      text:
        'Did it whisper its story to you as you brushed your fingers through its soft beds of hair?'
    },
    {
      type: 'divider'
    },
    {
      type: 'heading',
      text: 'Poem 2'
    },
    {
      type: 'paragraph',
      text: 'He saw her on a Monday morning'
    },
    {
      type: 'paragraph',
      text: 'Olive skin, olive hair, and olive eyes'
    },
    {
      type: 'paragraph',
      text: 'She had been plucked from a branch of rough, cracked bark'
    },
    {
      type: 'paragraph',
      text: 'Cloaked in slender leaves.'
    },
    {
      type: 'divider'
    },
    {
      type: 'heading',
      text: 'Poem 3'
    },
    {
      type: 'paragraph',
      text: 'All my life so far, I have loved more than one thing.'
    },
    {
      type: 'paragraph',
      text:
        'When I was a field hand in Shanghai I loved licking the dried salt off my fingers at the end of the day.'
    },
    {
      type: 'paragraph',
      text: 'When I was a grave digger I loved the smell of fresh snow.'
    },
    {
      type: 'paragraph',
      text:
        'When I was the wind I loved the trees, and when I was a tree I loved the wind.'
    }
  ]
};

export default Blog20170113;

import React from 'react';

const Poeteen = () => {
  return (
    <React.Fragment>
      <h3>Poeteen</h3>
      <p>
        The events would vary from reading/sharing favorite poems of well-known
        poets and discussing the meaning and techniques used, doing
        “springboard” writing exercises and writing poems, bringing in guest
        speakers, holding open mics, brainstorming about how to expand, having
        peer editing sessions, etc. I want to expose teenagers to poetry who
        haven’t studied it much before, and I want teens who already have a
        passion for it to continue exploring their potential. These meetings
        will connect youth poets around Portland and allow us to spread the
        ‘revolution’.
      </p>
      <p>
        I’ve started a similar initiative with Albertina Kerr, with a bit
        different focus. At Albertina Kerr, the goal is to have teens teaching
        depressed or at risk youth about poetry and helping them realize the
        healing qualities poetry holds. I was hoping to see if anyone who
        attends the IPRC meetings would be willing to volunteer with me at
        Albertina Kerr, and eventually expand to other similar programs.
      </p>
      <p>- Founder Sophia Mautz</p>
    </React.Fragment>
  );
};

export default Poeteen;

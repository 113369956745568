import {
  faGraduationCap,
  faPen,
  faFutbol,
  faPaintBrush,
  faComment,
  faAtlas,
  faBook
} from '@fortawesome/free-solid-svg-icons';

const programs = [
  {
    title: 'Lead and Inspire Peer Mentoring',
    icon: faGraduationCap,
    description:
      'Leading and inspiring youth to ensure that the obstacles they face do not define who they are.'
  },
  {
    title: 'Physical Activity',
    icon: faFutbol,
    description:
      'Classic activities that promote teamwork and communication, as well as an active lifestyle.'
  },
  {
    title: 'Tutoring',
    icon: faComment,
    description:
      'One-on-one tutoring between volunteers and children to help them succeed.'
  },
  {
    title: 'Poeteen',
    icon: faPen,
    description: 'Reviving the appeal and study of poetry for youth.'
  },
  {
    title: 'Arts and Crafts',
    icon: faPaintBrush,
    description:
      'Sparking creativity and passion in children to create a whole new perspective.'
  },
  {
    title: 'Academic Activities',
    icon: faBook,
    description:
      'Assisting youth in school with field trips and educational activities like read aloud.'
  },
  {
    title: 'Life Skills Classes',
    icon: faAtlas,
    description:
      'Fun, interactive classes that teach skills that promote the well-being and health of our children.'
  }
];

export default programs;

const Blog20170419 = {
  id: 20170419,
  title: 'Technology for Youth Activism',
  type: 'text',
  publishDate: '2017-04-19',
  summary:
    'When I set out to create my nonprofit, Kids First Project, two years ago, I quickly realized that my biggest challenge would be to find a way to effectively engage my community on the issue of youth homelessness.',
  body: [
    {
      type: 'lead',
      text:
        'When I set out to create my nonprofit, Kids First Project, two years ago, I quickly realized that my biggest challenge would be to find a way to effectively engage my community on the issue of youth homelessness.'
    },
    {
      type: 'paragraph',
      text:
        'While homelessness itself is overtly associated with an incredible amount of stigma, youth homelessness was largely invisible in Portland. My vision was for Kids First to be a traveling organization that brings educational and recreational programs to youth experiencing homelessness at local shelters and organizations. But first, I needed passionate volunteers.'
    },
    {
      type: 'paragraph',
      text:
        'My solution was to utilize social media and filmmaking to publicize the mission and purpose of Kids First. Through these media, I have been able to share with the world an issue that cannot be easily seen on the streets. Kids First’s mission video shows the viewer both the reality of youth homelessness and the impact that a person has when they take action in response to the issue, inspiring many to reach out and learn more about how to get involved with our work. As Kids First’s platform grew, my team created a blog that allows us to write about issues related to our work, whether it’s critiquing America’s educational system or sharing with our readers the concept of intersectional human rights, as well as to provide a platform for the youth we serve to publish their poetry and other writing.'
    },
    {
      type: 'paragraph',
      text:
        'Because of the power of technology, youth voices are able to be heard beyond our communities, reaching individuals and groups all around the world. Madi, an environmental activist and fellow HERlead Ambassador, uses GIS-based curricula to get youth more involved in service-learning. Her awareness campaign, which geocodes petition signatures protesting palm oil use, is a way to easily visualize impact and reach a greater audience. She also uses Skype to video chat with classrooms all around the world to introduce her community mapping model. This has led her to mentor many kids and connect them to different groups, creating a network of inspired change makers driven to take further action on the commercial palm oil industry.'
    },
    {
      type: 'paragraph',
      text:
        'Molly, another environmental activist, uses Google mapping technology to help students identify how they can contribute to their communities. She focuses on resources for people, animals, and the environment by mapping the points on a map. By visualizing where these places are located, students can easily see relationships and connections within their community. Molly’s use of Google mapping allows her to share her love for service-learning and pay it forward by encouraging students to create their own projects unique to their own communities.'
    },
    {
      type: 'paragraph',
      text:
        'At the heart of every advocacy project and youth-led organization is a strategy involving technology that enables youth to spread their impact. Without technology, Kids First Project would lack its committed volunteers and sponsors, Madi’s awareness campaign could not have gone international, and Molly would not be able to foster collective impact.'
    },
    {
      type: 'divider'
    },
    {
      type: 'author',
      name: 'Alisha Zhao',
      title: 'Executive Director'
    }
  ]
};

export default Blog20170419;

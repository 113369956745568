import headerImage from '../../assets/images/blog/2018-05-18.jpg';

const Blog20180518 = {
  id: 20180518,
  title: 'Business for Good: Kerry Horton’s First Plate LLC',
  type: 'text',
  publishDate: '2018-05-15',
  headerImage,
  summary:
    'Hi Kids First Project readers! My name is Kerry Horton, the current President of Lincoln High School’s Kids First Project chapter and the Communications Director of Kids First Project. Outside of Kids First Project, I run a business called First Plate LLC. I founded my biscotti business during my sophomore year in high school (2016) after I had injured myself via sports and found that I had spare time on my hands with a passion for baking and a curiosity for running a business. Ever since, I have been successfully supplying local coffee shops with biscotti. With the profits I’ve made from the sales, I have given some of it to Portland Homeless Family Solutions and Kids First Project.',
  body: [
    {
      type: 'lead',
      text:
        'Hi Kids First Project readers! My name is Kerry Horton, the current President of Lincoln High School’s Kids First Project chapter and the Communications Director of Kids First Project.'
    },
    {
      type: 'paragraph',
      text:
        'Outside of Kids First Project, I run a business called First Plate LLC. I founded my biscotti business during my sophomore year in high school (2016) after I had injured myself via sports and found that I had spare time on my hands with a passion for baking and a curiosity for running a business. Ever since, I have been successfully supplying local coffee shops with biscotti. With the profits I’ve made from the sales, I have given some of it to Portland Homeless Family Solutions and Kids First Project.'
    }
  ]
};

export default Blog20180518;

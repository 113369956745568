const AdvocacyBlog201906191 = {
  id: 201906191,
  title: "Miranda's Capitol Hill Pitch",
  type: 'text',
  publishDate: '2019-06-19',
  summary:
    "My name is Miranda Carstensen, and I'm one of the lucky youths who were gifted this opportunity to advocate for thousands of humans who were dealt a shit hand, just as I. September 2nd, 2017, I became homeless due to a domestically violent engagement, and a coexisting Xanax addiction. Today, I am enrolled in PCC working to get my CADC 1, I became a foster mom for cats in my towns homeless community, and I have been clean since September 3rd, 2017.",
  body: [
    {
      type: 'paragraph',
      text:
        "My name is Miranda Carstensen, and I'm one of the lucky youths who were gifted this opportunity to advocate for thousands of humans who were dealt a shit hand, just as I. September 2nd, 2017, I became homeless due to a domestically violent engagement, and a coexisting Xanax addiction. Today, I am enrolled in PCC working to get my CADC 1, I became a foster mom for cats in my towns homeless community, and I have been clean since September 3rd, 2017. In my opinion, becoming homeless was both the worst and best thing that ever happened to me. Though I've been in my apartment for a year, I'm still dealing with a multitude of setbacks and financial instability. But the extent of what I've learned has been monumental, and will last a lifetime."
    },
    {
      type: 'paragraph',
      text:
        "I became homeless from getting kicked out of a house by five people who, at the time, I  considered my family.  I was angry and upset, and felt betrayed. My thoughts on it (How could they kick me out when I have a problem? When I need help?) I learned soon were selfish. You see, it was pretty upsetting that they put me on the streets knowing about my addiction. But they weren't EDUCATED in knowing about addiction,  which led them to have no other option (the fist fights between me and my fiance in the front yard was another contributing factor, but those actions were also drug-fueled). What I mean  by that is, they were the type to a) keep their own addictions behind closed doors, and b) judge others' addictions because they didn't understand addiction as a disease- they merely understood it as a choice. They didn't realize that when you have a disease of any sort, you need a stable place to sleep and eat and rest; to recover. They didn't realize that when you have a disease, you need love and support through medications and medical appointments. They didn't realize that what I needed was not isolation and disclusion to discipline me from my drug use, but I needed love and friendship; I needed someone to play Magic the Gathering with to keep me from craving drugs; I needed a family to support me in going back to school to keep me busy, and to expand my psych. I needed complete honesty, dedication, and a conversation in \"how can we work through this problem?\"."
    },
    {
      type: 'paragraph',
      text:
        "I don't blame them for not understanding. You cannot punish a fish for swimming when it has never learned to walk; in the same way I cannot be mad for my past family doing what they felt they needed to do. But because of this, it has sparked a 10-mile long fire in my heart to bend over backwards to consistently educate each and every person I know in addiction. Being homeless for almost a year, I gained a lot of lovely friends and made extremely strong relationships in my community- a community where sobriety is a joke, and people will trade a pair of raybans for a 10 sack of crystal meth. And now, I get a chance tomorrow to stand on Capitol Hill to have not only my voice heard, but also every other voice heard that speaks with need and desperation in the homeless community."
    },
    {
      type: 'paragraph',
      text:
        "What I'd like to see in the nearer future is more resources and opportunities to not only educate all in the disease of addiction, but also see more support for those who are in addiction themselves. To me, this would look like using funding for  putting the topic of addiction in health classes, more in-depth training in occupations that deal with the demographic, and more decriminalization laws revolved around drug possession and intoxication (by replacing legal discipline with mandatory recovery programs as opposed to using thousands of tax dollars to keep addicts in jail). The main occupations who deal with people in addiction are homeless shelters, fast food workers, and mental health facilities. If these places receive better understanding for addiction, it would make it easier to help those struggling to obtain accurate and efficient help to not only get them sober, but to keep them sober. The problem with incarceration as a solution for drug related crimes is that, yes, it forces those to detox and be sober the time they are locked up, but it doesn't give support to keep them sober. It actually does the opposite, and drives those to want to use that much more due to the trauma that is jail. If instead of locking up a human for three years due to possession of heroin, we should in turn have them take a six month long recovery program so they not only get sober, but also learn the skills they need to stay sober. This option is also a LOT cheaper than keeping these humans in jail."
    },
    {
      type: 'paragraph',
      text:
        "I understand that change takes time, but any step closer to reach this would be a national achievement. The topic of Addiction has been swept under the rug for too long, and I believe it's time to bring it up at a larger scale."
    },
    {
      type: 'paragraph',
      text: '(For more info, please watch the documentary The Anonymous People)'
    },
    {
      type: 'divider'
    },
    {
      type: 'author',
      name: 'Miranda Carstensen',
      title: '2019 Fellow'
    }
  ]
};

export default AdvocacyBlog201906191;

import headerImage from '../../assets/images/blog/2015-09-01.jpg';

const Blog20150901 = {
  id: 20150901,
  title: 'Spotlight of the Week: Advisory Board Member Emily Mrusko',
  type: 'interview',
  publishDate: '2015-09-01',
  headerImage,
  summary:
    "Take a look into the owner of an Instagram famous cat (Madame George) and Oregon tree expert's world.",
  body: [
    {
      type: 'lead',
      text:
        "Take a look into the owner of an Instagram famous cat (Madame George) and Oregon tree expert's world."
    },
    {
      type: 'strong',
      text: 'What does Kids First Project mean to you?'
    },
    {
      type: 'paragraph',
      text:
        "Much of what we see at Portland Homeless Family Solutions are families with parent(s) that come from generational poverty; that is, that they themselves grew up in poverty, and perhaps in and out of homelessness, therefore making that lifestyle much of what they know. The Kids First Project provides an opportunity for youth experiencing homeless to exit from that brutal cycle that's very hard to escape. Providing opportunity, raising awareness, and valuable educational experiences are the tools to escape generational poverty, which is what the Kids First Project strives for."
    },
    {
      type: 'strong',
      text:
        'What is your favorite memory while working with families experiencing homelessness?'
    },
    {
      type: 'paragraph',
      text:
        'The experience of working with families experiencing homelessness has been incredibly eye-opening. My favorite memory is when I first began working for PHFS. I was in an operational role, and didn\'t have much interaction with the families at this point. I was at the office by myself, and a family who had just been approved for housing came by to pick up their things. After connecting with leadership staff, I took the family downstairs at the Goose to collect their belongings. The entire time, the father of the family could not stop thanking me for the services that PHFS has provided them, what positive influences that the staff and volunteers provided them, and just how grateful he was to finally have a house for him, his wife, and his 2-year-old daughter. That was the first time I really got to see the impact that the program has on our guests, and how much of an impact that a "small" community can have on someone.'
    },
    {
      type: 'strong',
      text: 'If you could be anybody for a day, who would it be?'
    },
    {
      type: 'paragraph',
      text:
        'Like the rest of America, I just love Amy Schumer. I just really want to get in her mind, feel her views on media/feminism/etc, and have her sense of humor playing over and over and over. And I think one day would be just the right amount of time... :)'
    },
    {
      type: 'strong',
      text: 'How many trees are there in Oregon?'
    },
    {
      type: 'paragraph',
      text: '3,643,094,124, approximately.'
    },
    {
      type: 'strong',
      text: "What's your favorite 90s jam?"
    },
    {
      type: 'paragraph',
      text:
        "I don't know about a specific jam, but I really love the Ja Rule/Ashanti days. A lot."
    },
    {
      type: 'strong',
      text: 'Favorite place in Portland:'
    },
    {
      type: 'paragraph',
      text:
        "I love Mt. Tabor! It's such a nice way to get away, go for a quiet walk, but all while overlooking the city and still feeling connected."
    },
    {
      type: 'strong',
      text: 'What is your intent?'
    },
    {
      type: 'paragraph',
      text:
        '"Be" - I have a necklace that says "be". I wear it everyday as a reminder to just be in the moment; to be appreciative, to breathe.'
    }
  ]
};

export default Blog20150901;

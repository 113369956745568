const Blog20210611 = {
  id: 20210611,
  title: 'More COVID-19 Testing Needed for Portland’s Homeless',
  type: 'text',
  publishDate: '2021-06-11',
  summary:
    'Though reported cases of COVID-19 among homeless individuals in Portland appear low, insufficient data remains regarding the true spread of the virus within the population. According to Street Roots on November 4th of 2020, a total of 71 people who contracted COVID-19 reported that they lacked housing at some point in the previous 12 months.',
  body: [
    {
      type: 'lead',
      text:
        'Though reported cases of COVID-19 among homeless individuals in Portland appear low, insufficient data remains regarding the true spread of the virus within the population. According to Street Roots on November 4th of 2020, a total of 71 people who contracted COVID-19 reported that they lacked housing at some point in the previous 12 months. This number has likely grown in the ensuing months, but data is not available. In November, an outbreak of up to 20 individuals occurred in Union Gospel Mission, a shelter in Portland. While recent statistics do not indicate high levels of COVID-19 testing, the City of Portland, through the Joint Office of Homeless Services, is screening homeless individuals for symptoms of coronavirus. Low levels of testing may simply be due to state and nationwide trends of declining COVID-19 positivity rates and incidence as vaccinations rates expand and vaccines made easily available in local pharmacies and stores.'
    },
    {
      type: 'paragraph',
      text:
        'The Joint Office of Homeless Services began planning to mitigate outbreaks of COVID-19 among the homeless population from as early as January of 2020. On March 11, 2020, Portland and Multnomah County declared states of emergency and ordered homeless shelters, which were to close in the spring, to remain open through the pandemic. Alongside an eviction moratorium to prevent additional people from being displaced due to lockdowns and loss of income, the City of Portland expanded motel vouchers to individuals over 60 and those with underlying conditions. This provided safer, socially-distant shelters to individuals at-risk of contracting serious cases of COVID-19.'
    },
    {
      type: 'paragraph',
      text:
        'The Joint Office also coordinated with numerous organizations throughout Portland to expand meal assistance programs. The Office partnered with organizations to provide meals at motels and socially-distant shelters, granted $260,000 to charities to provide up to 10,000 meals per week, and distributed more than 28,000 sack lunches between April 29th and August 6th. The Office also supported fundraisers for severe weather supplies and hosted a supply depot for items such as tarps, blankets, jackets, and sanitizing items such as hand sanitizer. The City also began implementing sanitation stations throughout the areas of Portland with the highest amount of homelessness densities and ceased police “sweep” operations, which had periodically been removing homeless tents from public spaces.'
    },
    {
      type: 'paragraph',
      text:
        'As of March 29th, COVID-19 vaccinations were open to individuals experiencing homelessness under Phase 1B, but there is currently a dearth of evidence regarding how frequently homeless populations have access to COVID-19 test kits in Portland. If you are interested in helping with efforts to provide assistance to those facing housing insecurity, the City of Portland has provided a list of resources on how you can help:'
    },
    {
      type: 'link',
      text: 'https://www.portlandoregon.gov/toolkit/69886',
      src: 'https://www.portlandoregon.gov/toolkit/69886'
    }
  ]
};

export default Blog20210611;

import React from 'react';

const Tutoring = () => {
  return (
    <React.Fragment>
      <h3>Tutoring</h3>
      <p>
        With an emphasis on education and support in academic achievement, our
        one on one tutoring will bring in volunteers of all ages, whether it is
        a math teacher or high school chemistry student, to bring their
        expertise to teach a child in shelter. Giving direct attention and
        guidance, the children in shelter will be equipped with the skills and
        material necessary to succeed in school.
      </p>
    </React.Fragment>
  );
};

export default Tutoring;

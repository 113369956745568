import headerImage from '../../assets/images/blog/2017-01-15.png';

const Blog20170115 = {
  id: 20170115,
  title: 'Volunteer Spotlight of the Month: Jackie Zhang',
  type: 'interview',
  publishDate: '2017-01-15',
  headerImage,
  summary:
    'A current sophomore at Sunset High School, Jackie is our first Volunteer of the Month! Read on to learn more about her love for gelato and Keeping Up with the Kardashians.',
  body: [
    {
      type: 'lead',
      text:
        'A current sophomore at Sunset High School, Jackie is our first Volunteer of the Month! Read on to learn more about her love for gelato and Keeping Up with the Kardashians.'
    },
    {
      type: 'strong',
      text: 'What does Kids First Project mean to you?'
    },
    {
      type: 'paragraph',
      text:
        'I volunteer at Kids First because I love hanging out with the kids here, and I learn so much from them every time I come. The people at Kids First Project keep me grounded and inspire me to become a better person.'
    },
    {
      type: 'strong',
      text: 'What is your favorite memory while volunteering with us?'
    },
    {
      type: 'paragraph',
      text:
        'There are so many to choose from! One of my favorites is when I had a paper airplane throwing contest with a group of boys. We must have made over twenty different airplanes using different techniques and types of paper, and then we tested them to see which ones flew the farthest.'
    },
    {
      type: 'strong',
      text: 'If you ran a food cart in Portland, what would it serve?'
    },
    {
      type: 'paragraph',
      text:
        "Gelato!! Absolutely addicted to that stuff. But I wouldn't be able to stop eating it, so I might go out of business."
    },
    {
      type: 'strong',
      text: 'Who would play you in the movie about your life?'
    },
    {
      type: 'paragraph',
      text:
        'I would be over the moon if Aubrey Anderson-Emmons played me as a kid, I love her character in Modern Family!'
    },
    {
      type: 'strong',
      text: 'What is something most people don’t know about you?'
    },
    {
      type: 'paragraph',
      text:
        "I'm secretly obsessed with Keeping Up with the Kardashians (shhhhh)!"
    },
    {
      type: 'strong',
      text: 'How many trees are there in Oregon?'
    },
    {
      type: 'paragraph',
      text:
        'Three million? No, wait, 50 million! 50 billion! It has to be some crazy huge number...'
    },
    {
      type: 'strong',
      text: 'What is your intent?'
    },
    {
      type: 'paragraph',
      text: 'Purpose.'
    }
  ]
};

export default Blog20170115;

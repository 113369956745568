import headerImage from '../../assets/images/blog/2017-01-17-1.jpg';
import image1 from '../../assets/images/blog/2017-01-17-2.jpg';

const Blog20170117 = {
  id: 20170117,
  title: 'Program Nights at Turning Point!',
  type: 'text',
  publishDate: '2017-01-17',
  headerImage,
  summary:
    "Hi guys! My name is Emily Ma and I am a volunteer leader and the Director of Finance and Administration. This is my first time writing a blog post but I'm so excited to write about my amazing experiences at our location in SW Portland.",
  body: [
    {
      type: 'lead',
      text:
        "Hi guys! My name is Emily Ma and I am a volunteer leader and the Director of Finance and Administration. This is my first time writing a blog post but I'm so excited to write about my amazing experiences at our location in SW Portland."
    },
    {
      type: 'paragraph',
      text:
        'For the past two years, I have been involved at Turning Point, which is a transitional housing organization for families with kids. The STEM programs I have led there include making mini-catapults, stimulating an egg drop activity, and making candy cars. Because most of the kids there are toddlers, I sometimes divert my focus on science related activities and instead hold fun activities such as finger painting, creating holiday cards, and making clay sculptures. We also have had guest speakers come in to talk with the kids. Recently, three Lincoln High School students presented on the topic of culture and language for their CAS project.'
    },
    {
      type: 'image',
      image: image1
    },
    {
      type: 'paragraph',
      text:
        'One of my favorite activities that I did with the kids was when we made mini-catapults. It was a thrill to see them shoot pom poms everywhere trying to be the first to get one into a paper bag to get a prize.'
    },
    {
      type: 'paragraph',
      text:
        'If anyone is interested in participation at Turning Point or would like to lead an activity there, feel free to contact me. My email is (emilycatma@gmail.com). You can also sign up for shifts at Turning Point anytime. We will be making balloon rockets this Thursday!'
    }
  ]
};

export default Blog20170117;
